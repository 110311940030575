import { Injectable } from '@angular/core';
import { CommonRepositoryAbstract } from '@freddy/common';
import { CreditTransaction } from '@freddy/models';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class CreditTransactionRepository extends CommonRepositoryAbstract<CreditTransaction> {
  static DOC_PATH = 'credit-transactions';

  constructor(firestore: Firestore) {
    super(firestore);
  }

  protected getDocPath(): string {
    return CreditTransactionRepository.DOC_PATH;
  }
}
