import { Injectable, inject } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { CreditTransaction, CreditTransactionType, User } from '@freddy/models';
import { PaginatedResult, QueryParam } from '@freddy/common';

import { CreditTransactionRepository } from '../repository/credit-transaction.repository';
import { catchError, firstValueFrom, tap } from 'rxjs';
import { GuidUtils } from '../../../core/utils/guid.utils';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import {
  AddCreditsAction,
  CreditTransactionsFetchedAction,
  ListCreditTransactionsAction,
  TransferCreditsAction,
} from './credit.actions';
import { AuthenticationState } from '../../../core/auth/store/authentication.store';
import { or, where } from '@angular/fire/firestore';

export const CREDIT_TRANSACTION_STATE_TOKEN =
  new StateToken<CreditTransactionStateModel>('creditTransaction');

export interface CreditTransactionStateModel {
  layout: {
    loading: boolean;
  };
  list: PaginatedResult<CreditTransaction>;
}

const defaultState: CreditTransactionStateModel = {
  layout: {
    loading: false,
  },
  list: {
    currentPage: 0,
    items: [],
    prevDisabled: true,
    nextDisabled: false,
  },
};

@State<CreditTransactionStateModel>({
  name: CREDIT_TRANSACTION_STATE_TOKEN,
  defaults: defaultState,
})
@Injectable()
export class CreditTransactionState {
  private creditTransactionRepository = inject(CreditTransactionRepository);
  private toastService = inject(HotToastService);
  private translateService = inject(TranslateService);
  private store = inject(Store);

  @Selector()
  static transactions(
    state: CreditTransactionStateModel,
  ): PaginatedResult<CreditTransaction> {
    return state.list;
  }

  @Selector()
  static isLoading(state: CreditTransactionStateModel): boolean {
    return state.layout.loading;
  }

  @Action(ListCreditTransactionsAction)
  listTransactions(
    ctx: StateContext<CreditTransactionStateModel>,
    action: ListCreditTransactionsAction,
  ) {
    ctx.patchState({
      layout: {
        loading: true,
      },
    });

    // Check if user is admin, partner, or manager to determine query constraints
    const user: User | null = this.store.selectSnapshot(
      AuthenticationState.user,
    );
    const queryConstraints = action.query.where || [];
    let queryCompositeConstraints = undefined;

    // Filter based on user role
    if (user) {
      if (user.admin) {
        // Admin can see all transactions
      } else if (user.partner) {
        queryCompositeConstraints = or(
          where('parentOrganizationId', '==', user.organization),
          where('organizationId', '==', user.organization),
        );
      } else if (user.manager) {
        // Manager can only see their own organization's transactions
        queryConstraints.push(where('organizationId', '==', user.organization));
      }
    }

    const query: QueryParam = {
      ...action.query,
      where: queryConstraints,
      compositeWhere: queryCompositeConstraints,
      currentPage: ctx.getState().list.currentPage,
    };

    return this.creditTransactionRepository.list(query).pipe(
      tap((result) => {
        ctx.dispatch(new CreditTransactionsFetchedAction(result));
      }),
      catchError((error) => {
        this.toastService.error(
          this.translateService.instant(
            'credit.creditTransaction.errors.listFailed',
          ),
        );
        console.error('Error fetching credit transactions:', error);
        ctx.patchState({
          layout: {
            loading: false,
          },
        });
        throw error;
      }),
    );
  }

  @Action(CreditTransactionsFetchedAction)
  transactionsFetched(
    ctx: StateContext<CreditTransactionStateModel>,
    action: CreditTransactionsFetchedAction,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      layout: {
        loading: false,
      },
      list: {
        ...action.transactions,
        lastItem:
          action.transactions.currentPage > 1 &&
          action.transactions.items.length === 0
            ? state.list.lastItem
            : action.transactions.lastItem,
        firstItem:
          action.transactions.currentPage > 1 &&
          action.transactions.items.length === 0
            ? state.list.firstItem
            : action.transactions.firstItem,
      },
    });
  }

  @Action(AddCreditsAction)
  async addCredits(
    ctx: StateContext<CreditTransactionStateModel>,
    action: AddCreditsAction,
  ) {
    ctx.patchState({
      layout: {
        loading: true,
      },
    });

    try {
      // Create transaction record
      const transaction: CreditTransaction = {
        uid: GuidUtils.generateUuid(),
        amount: action.amount,
        type: CreditTransactionType.CREDIT_ADDED,
        organizationId: action.targetOrganization.organizationSlug,
        organizationName: action.targetOrganization.organizationName,
        description: action.description || 'Credit added by admin',
        createdAt: new Date(),
      };

      await Promise.all([
        firstValueFrom(this.creditTransactionRepository.create(transaction)),
      ]);

      this.toastService.success(
        this.translateService.instant(
          'credit.creditTransaction.success.creditsAdded',
          {
            amount: action.amount,
            organization: action.targetOrganization.organizationName,
          },
        ),
      );

      ctx.patchState({
        layout: {
          loading: false,
        },
      });
      ctx.dispatch(
        new ListCreditTransactionsAction({
          orderBy: 'createdAt',
        }),
      );
    } catch (error) {
      this.toastService.error(
        this.translateService.instant(
          'credit.creditTransaction.errors.addFailed',
        ),
      );
      console.error('Error adding credits:', error);
      ctx.patchState({
        layout: {
          loading: false,
        },
      });
      throw error;
    }
  }

  @Action(TransferCreditsAction)
  async transferCredits(
    ctx: StateContext<CreditTransactionStateModel>,
    action: TransferCreditsAction,
  ) {
    ctx.patchState({
      layout: {
        loading: true,
      },
    });

    try {
      // Verify source has enough credits
      const sourceCredits = action.sourceOrganization.credits || 0;
      if (sourceCredits < action.amount) {
        throw new Error('Insufficient credits for transfer');
      }

      // Create transaction record
      const transaction: CreditTransaction = {
        uid: GuidUtils.generateUuid(),
        amount: action.amount,
        type: CreditTransactionType.CREDIT_TRANSFER,
        organizationId: action.targetOrganization.organizationSlug,
        organizationName: action.targetOrganization.organizationName,
        parentOrganizationId: action.sourceOrganization.organizationSlug,
        parentOrganizationName: action.sourceOrganization.organizationName,
        description: action.description || 'Credit transfer',
      };

      // Using transaction to ensure all operations succeed or fail together
      await Promise.all([
        firstValueFrom(this.creditTransactionRepository.create(transaction)),
      ]);

      this.toastService.success(
        this.translateService.instant(
          'credit.creditTransaction.success.creditsTransferred',
          {
            amount: action.amount,
            source: action.sourceOrganization.organizationName,
            target: action.targetOrganization.organizationName,
          },
        ),
      );
      ctx.dispatch(
        new ListCreditTransactionsAction({
          orderBy: 'createdAt',
        }),
      );
      ctx.patchState({
        layout: {
          loading: false,
        },
      });
    } catch (error) {
      this.toastService.error(
        this.translateService.instant(
          'credit.creditTransaction.errors.transferFailed',
        ),
      );
      console.error('Error transferring credits:', error);
      ctx.patchState({
        layout: {
          loading: false,
        },
      });
      throw error;
    }
  }
}
