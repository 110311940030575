import { CreditTransaction, Organization } from '@freddy/models';
import { PaginatedResult, QueryParam } from '@freddy/common';

export class AddCreditsAction {
  static readonly type = '[CreditTransaction] Add Credits';
  constructor(
    public readonly targetOrganization: Organization,
    public readonly amount: number,
    public readonly description?: string,
  ) {}
}

export class TransferCreditsAction {
  static readonly type = '[CreditTransaction] Transfer Credits';
  constructor(
    public readonly sourceOrganization: Organization,
    public readonly targetOrganization: Organization,
    public readonly amount: number,
    public readonly description?: string,
  ) {}
}

export class ListCreditTransactionsAction {
  static readonly type = '[CreditTransaction] List Transactions';
  constructor(public readonly query: QueryParam) {}
}

export class CreditTransactionsFetchedAction {
  static readonly type = '[CreditTransaction] Transactions Fetched';
  constructor(
    public readonly transactions: PaginatedResult<CreditTransaction>,
  ) {}
}
